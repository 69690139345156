import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { StyleProps } from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { noop } from "lodash";
import React from "react";
import copyText from "../copyText";
import IconStarOutline from "../icons/IconStarOutline";

const Size = {
  jumbo: "jumbo",
  large: "large",
  medium: "medium",
  small: "small",
  tiny: "tiny",
} as const;
export type Size = keyof typeof Size;

const StyledButton = styled(Button)<
  StyleProps & { onlyIcon: boolean; isFavorite: boolean }
>(({ background, onlyIcon, size, theme, isFavorite, disabled }) => ({
  background: (() => {
    if (disabled) return "none";
    if (onlyIcon) return "none";
    if (background) return background;
    return;
  })(),
  cursor: "pointer",
  padding: 0,
  width: 100,

  "&:hover": {
    backgroundColor: (() => {
      if (onlyIcon) return "transparent";
      return;
    })(),

    boxShadow: "none",
    outline: "none",
  },

  '& [role="img"]': {
    color: (() => {
      if (isFavorite) return theme.report_favorite_color;
      return;
    })(),
  },

  '& [role="img"]:only-child': {
    height: theme[`Button_iconOnlySize_${size}`],
    margin: 0,
    width: theme[`Button_iconOnlySize_${size}`],
  },
}));

type FavoriteButtonProps = {
  color?: string;
  disabled?: boolean;
  favorited: boolean;
  iconOnly?: boolean;
  iconSize?: string;
  size?: Size;
  onClick: () => void;
} & StyleProps;

export function FavoriteButton(props: FavoriteButtonProps) {
  const theme = useTheme();

  const favoritedIcon = () => {
    return props.favorited ? (
      <Icon
        backgroundColorOnHover={theme.report_favorite_color_hover}
        color={theme.report_favorite_color}
        icon={faStar}
        size="sm"
      />
    ) : (
      <IconStarOutline
        backgroundColorOnHover={theme.report_favorite_color}
        color={props.color ? props.color : theme.table_header_background_color}
        size="20px"
      />
    );
  };

  const onlyIcon = props.iconOnly ?? false;

  const buttonProps = {
    ...props,
    size: props.size ?? "medium",
    onlyIcon,
    isFavorite: props.favorited,
  };

  return (
    <StyledButton
      {...buttonProps}
      onClick={() => (props.disabled ? noop() : props.onClick())}
    >
      <Flex alignItems="center" justifyContent="center">
        {favoritedIcon()}
        {!props.iconOnly && <Text>{copyText.favoriteButtonLabel}</Text>}
      </Flex>
    </StyledButton>
  );
}
