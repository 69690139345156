import { useAuthHandler } from "@/api/AuthHandlerProvider";
import copyText from "@/constants/copyText";
import externalLinks from "@/constants/externalLinks";
import paths from "@/constants/paths";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import Avatar from "@/ui-lib/components/Avatar";
import Dropdown from "@/ui-lib/components/Dropdown";
import getMergeState from "@/utils/getMergeState";
import {
  storage,
  STORAGE_KEY_MSP_TENANT_ID,
  updateTenantID,
} from "@/utils/window";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faChevronUp,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { TenantType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import AnnounceKit from "announcekit-react";
import { sortBy } from "lodash";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthenticatedUserEntity } from "../api/core/types";
import CaseFormContainer from "../features/case-management/components/CaseFormContainer";
import useGetTenants from "../features/global-admin/hooks/useGetTenants";
import useGatekeeper from "../hooks/useGatekeeper";
import useGetTenantByID from "../hooks/useGetTenantByID";
import { useMspStore } from "../lib/zustand";
import { getFullName } from "../utils/UserUtils";
import GlobalDatePickerManagementContainer from "./GlobalDatePickerManagementContainer";
import GlobalFilterManagementContainer from "./GlobalFilterManagementContainer";
import TenantModal from "./TenantModal";

const MODAL_KEY_TENANT_SELECT = "MODAL_KEY_TENANT_SELECT";
const MODAL_KEY_MSP_TENANT_SELECT = "MODAL_KEY_MSP_TENANT_SELECT";

const TENANT_NAME_WIDTH = 200;
export const TOP_NAV_HEIGHT = 64;
const SMALL_SCREEN_SIZE = 1600;

interface Props {
  authenticatedUser: AuthenticatedUserEntity;
  enableCaseManagement: boolean;
  enableGlobalFiltering: boolean;
  isSharedView: boolean;
  width: string;
}

interface State {
  modalKey: string;
  showCopiedMessage: boolean;
  showDropdown: boolean;
}

const initialState: State = {
  modalKey: "",
  showCopiedMessage: false,
  showDropdown: false,
};

export default function TopNav(props: Props): JSX.Element {
  const authHandler = useAuthHandler();
  const gatekeeper = useGatekeeper();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // State
  //

  const mspStore = useMspStore();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _tenants = [] } = useGetTenants({
    enabled: gatekeeper.canReadTenantsSystem,
  });

  // Need the selected tenant for display purposes here.
  const { data: mspParentTenant, isLoading: isLoadingMspParentTenant } =
    useGetTenantByID(mspStore.selectedParentTenantID as string, {
      enabled:
        gatekeeper.canReadTenantsPartner && !!mspStore.selectedParentTenantID,
    });

  //
  // Interaction Handlers
  //

  function handleShare(): () => void {
    navigator.clipboard.writeText(window.location.href);
    mergeState({ showCopiedMessage: true });
    const timeId = setTimeout(() => {
      mergeState({ showCopiedMessage: false });
    }, 3000);

    return () => {
      clearTimeout(timeId);
    };
  }

  function handleSwitchMspTenant(tenantID: string) {
    // Store this in local storage for convenience when refreshing page or switching tabs.
    // Only for Ternary employees.
    storage.setItem(STORAGE_KEY_MSP_TENANT_ID, tenantID);

    mspStore.setParentTenantID(tenantID);

    mergeState({ modalKey: "" });
  }

  //
  // Render
  //

  const dropdownItems = [
    {
      label: copyText.topNavDropdownItemSwitchTenant,
      onClick: () =>
        mergeState({ showDropdown: false, modalKey: MODAL_KEY_TENANT_SELECT }),
    },
    {
      label: copyText.topNavDropdownItemCases,
      onClick: () => navigate(paths._cases),
    },
    {
      label: copyText.topNavDropdownItemSettings,
      onClick: () => navigate(paths._settings),
    },
  ];

  if (gatekeeper.canAccessInternalAdmin) {
    dropdownItems.push(
      ...[
        {
          label: mspStore.selectedParentTenantID
            ? copyText.topNavDropdownItemChangeMspTenant
            : copyText.topNavDropdownItemSelectMspTenant,
          onClick: () =>
            mergeState({
              showDropdown: false,
              modalKey: MODAL_KEY_MSP_TENANT_SELECT,
            }),
        },
        {
          label: copyText.topNavDropdownItemInternalAdmin,
          onClick: () => navigate(paths._internalAdmin),
        },
      ]
    );
  }

  dropdownItems.push({
    label: copyText.topNavDropdownLogout,
    onClick: () => authHandler.logout(),
  });

  // Removes settings page when in shared view.
  if (props.isSharedView) {
    dropdownItems.splice(2, 1);
  }

  const tenants = props.authenticatedUser.tenants.sort((a, b) =>
    a.name < b.name ? -1 : 1
  );

  if (tenants.length === 1) {
    dropdownItems.shift();
  }

  const resizeTenantName =
    window.innerWidth < SMALL_SCREEN_SIZE &&
    props.enableCaseManagement &&
    props.enableGlobalFiltering;

  const mspTenants = sortBy(
    _tenants.filter((tenant) => tenant.type === TenantType.MSP_PARENT),
    "name"
  );

  function renderModal() {
    switch (state.modalKey) {
      case MODAL_KEY_MSP_TENANT_SELECT: {
        return (
          <TenantModal
            currentTenantID={mspStore.selectedParentTenantID}
            isOpen
            tenants={mspTenants}
            title={copyText.tenantModalChangeMspTenantTitle}
            onClose={() => mergeState({ modalKey: "" })}
            onSelect={handleSwitchMspTenant}
          />
        );
      }
      case MODAL_KEY_TENANT_SELECT: {
        return (
          <TenantModal
            currentTenantID={props.authenticatedUser.tenant.id}
            isOpen
            tenants={tenants}
            title={copyText.tenantModalChangeActiveTenantTitle}
            onClose={() => mergeState({ modalKey: "" })}
            onSelect={updateTenantID}
          />
        );
      }
    }
  }

  const isMspAdminLocation = location.pathname.startsWith(paths._mspMgmt);

  const tenantName =
    mspParentTenant && isMspAdminLocation
      ? mspParentTenant.name
      : props.authenticatedUser.tenant.name;

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.top_nav_background_color}
      height={TOP_NAV_HEIGHT}
      justifyContent="space-between"
      padding={`${theme.space_md} ${theme.space_xl}`}
      position="fixed"
      zIndex={theme.zIndex_200}
      width={props.width}
    >
      {renderModal()}
      <Flex alignItems="center">
        <Box maxWidth={resizeTenantName ? TENANT_NAME_WIDTH : undefined}>
          {isMspAdminLocation && isLoadingMspParentTenant ? (
            <EmptyPlaceholder loading skeletonVariant="bar" />
          ) : (
            <Text appearance="h4" marginRight={theme.space_md} truncate>
              {tenantName}
            </Text>
          )}
        </Box>
        <GlobalFilterManagementContainer
          authenticatedUser={props.authenticatedUser}
          isEnabled={props.enableGlobalFiltering}
        />
        <CaseFormContainer
          isEnabled={props.enableCaseManagement && !props.isSharedView}
        />
      </Flex>
      <Flex alignItems="center">
        <GlobalDatePickerManagementContainer />
        <Tooltip
          content={copyText.shareButtonTooltip}
          delayHide={3000}
          hide={!state.showCopiedMessage}
        >
          <Button
            iconStart={
              <Icon color={theme.text_color_inverse} icon={faUserFriends} />
            }
            marginRight={theme.space_lg}
            primary
            size="small"
            onClick={handleShare}
          >
            {copyText.shareButton}
          </Button>
        </Tooltip>
        <Box marginRight={theme.space_lg} position="relative">
          <AnnounceKit
            widget={externalLinks.announceKitWidgetID}
            widgetStyle={{ position: "absolute", right: "-5px", top: "-8px" }}
          >
            <Button size="small">{copyText.announceKitButtonLabel}</Button>
          </AnnounceKit>
        </Box>
        <Dropdown
          isOpen={state.showDropdown}
          options={dropdownItems}
          placement="bottom-end"
          onClose={() => mergeState({ showDropdown: false })}
          onOpen={() => mergeState({ showDropdown: true })}
        >
          <Flex alignItems="center" cursor="pointer">
            <Avatar src={props.authenticatedUser.avatarSrc} />
            <Text marginHorizontal={theme.space_sm} truncate>
              {getFullName(props.authenticatedUser)}
            </Text>
            <Icon
              icon={state.showDropdown ? faChevronUp : faChevronDown}
              size="xs"
              color={theme.text_color_secondary}
            />
          </Flex>
        </Dropdown>
      </Flex>
    </Flex>
  );
}
